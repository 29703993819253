import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Snackbar, 
  Alert, 
  CircularProgress, 
  Backdrop
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Auth';
import { ReactComponent as Logo } from './assets/logo.svg';

const SignInApp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true); // Initial loading state set to true
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
  const { secretToken, setSecretToken, setUserId, apiBaseUrl } = useAuth();
  const navigate = useNavigate();

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: apiBaseUrl,
      headers: {
        Authorization: `Bearer ${secretToken}`,
      },
    });
  }, [apiBaseUrl, secretToken]);

  const healthCheck = useCallback(async () => {
    try {
      await axiosInstance.get('/'); // Health check call
    } catch (error) {
      console.error('Health check failed', error);
    } finally {
      setLoading(false); // Stop loading when the call completes
    }
  }, [axiosInstance]);

  useEffect(() => {
    healthCheck();
  }, [healthCheck]);

  const handleSignIn = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/users/login', { email, password });
      if (response.status === 200) {
        const { secret_token, user_id } = response.data;
        if (secret_token && user_id) {
          setSecretToken(secret_token);
          setUserId(user_id);
          setSnackbar({ open: true, message: 'Login successful!', severity: 'success' });
          setTimeout(() => navigate('/agents'), 500);
        }
      }
    } catch (error) {
      const message = error.response?.status === 401
        ? 'Invalid credentials. Please try again!'
        : 'An error occurred. Please try again later.';
      setSnackbar({ open: true, message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') handleSignIn();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'error' });
  };

  if (loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading, please wait...</Typography>
        </Box>
      </Backdrop>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" sx={{ backgroundColor: '#f0f0f0' }}>
      <Container maxWidth="sm" sx={{ backgroundColor: 'rgb(246, 246, 246)', borderRadius: '15px', padding: '20px' }}>
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Logo style={{ width: '200px', height: 'auto', fill: '#008ec2', padding: '40px' }} />
        </Box>

        <Box sx={{ marginBottom: '20px' }}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleEnterPress}
          />
        </Box>

        <Box sx={{ marginBottom: '20px' }}>
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleEnterPress}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSignIn} 
            disabled={loading}
            sx={{ textTransform: 'none', fontWeight: 'bold', backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#1565c0' } }}
          >
            Sign In
          </Button>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default SignInApp;
