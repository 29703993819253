import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Chip,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircle from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from './Auth';

const ActionsApp = () => {
  const { agentId } = useParams();
  const [topics, setTopics] = useState([]);
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newAction, setNewAction] = useState({
    action_name: '',
    action_description: '',
    action_type: '',
    provider: '',
    request_method: '',
    request_url: '',
    request_headers: '',
    request_query_params: '',
    request_body: '',
    connection_string: '',
    query_text: '',
    topic_id: '',
    requires_confirmation: false,
  });
  const [newTopic, setNewTopic] = useState({ topic_name: '', custom_prompt: '', input_arguments: '{}' });
  const [availableActionNames, setAvailableActionNames] = useState([]);
  const [errorModal, setErrorModal] = useState({ open: false, title: '', description: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [openAddEditActionModal, setOpenAddEditActionModal] = useState(false);
  const [openAddEditTopicModal, setOpenAddEditTopicModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [agentInfo, setAgentInfo] = useState({ agent_name: 'Agent' });
  const [deleteDialog, setDeleteDialog] = useState({ open: false, actionId: null, topicId: null });
  const descriptionRef = useRef(null);
  const [collapsedAccordions, setCollapsedAccordions] = useState({});

  const navigate = useNavigate();
  const { secretToken, apiBaseUrl } = useAuth();

  const PROVIDER_ACTIONS = {
    Native: ['Answer Question', 'Retrieve User Info', 'Delete Account'],
    Doorbell: ['Escalate Conversation'],
    Stripe: ['Refund Payment', 'Cancel Subscription'],
  };

  const ACTION_TYPE_MAP = {
    'Answer Question': 'Knowledgebase Query',
    'Retrieve User Info': 'SQL Query',
    'Delete Account': 'API Call',
    'Escalate Conversation': 'API Call',
    'Refund Payment': 'API Call',
    'Cancel Subscription': 'API Call',
  };

  const HTTP_METHODS = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'];

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: apiBaseUrl,
      headers: {
        Authorization: `Bearer ${secretToken}`,
      },
    });
  }, [apiBaseUrl, secretToken]);

  useEffect(() => {
    const initAgent = async () => {
      try {
        const response = await axiosInstance.get(`/agents/${agentId}`);
        setAgentInfo(response.data);
      } catch (err) {
        console.error('Loading agent failed', err);
      }
    };
    initAgent();
  }, [axiosInstance, agentId]);

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const handleAccordionToggle = (topicId) => {
    setCollapsedAccordions((prev) => ({
      ...prev,
      [topicId]: !prev[topicId], // Toggle the specific accordion's collapsed state
    }));
  };

  const fetchActions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/actions', {
        params: { agent_id: agentId },
      });
      setActions(response.data);
    } catch (error) {
      handleError('Failed to fetch actions', error);
    } finally {
      setLoading(false);
    }
  }, [axiosInstance, agentId]);

  const fetchTopics = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/topics', {
        params: { agent_id: agentId },
      });
      setTopics(response.data);
    } catch (error) {
      handleError('Failed to fetch topics', error);
    }
  }, [axiosInstance, agentId]);

  const handleError = (defaultMessage, error) => {
    const errorMessage = error.response?.data?.error_description || defaultMessage;
    const errorName = error.response?.data?.error_name || 'Error';
    setErrorModal({ open: true, title: errorName, description: errorMessage });
  };

  const handleAddEditAction = async () => {
    try {
      const updatedAction = { ...newAction };
      if (newAction.action_type === 'API Call') {
        updatedAction.request_headers = newAction.request_headers;
        updatedAction.request_query_params = newAction.request_query_params;
        updatedAction.request_body = newAction.request_body;
      }

      if (editMode) {
        await axiosInstance.put(`/actions/${newAction.action_id}`, { ...updatedAction, agent_id: agentId });
        showSnackbar('Action updated successfully', 'success');
      } else {
        await axiosInstance.post('/actions', { ...updatedAction, agent_id: agentId });
        showSnackbar('Action added successfully', 'success');
      }
      fetchActions();
      handleCloseAddEditActionModal();
    } catch (error) {
      handleError(editMode ? 'Failed to update action' : 'Failed to add action', error);
    }
  };

  const handleAddEditTopic = async () => {
    try {
      const updatedTopic = { ...newTopic, input_arguments: newTopic.input_arguments || '{}' };
      if (editMode) {
        await axiosInstance.put(`/topics/${newTopic.topic_id}`, { ...updatedTopic, agent_id: agentId });
        showSnackbar('Topic updated successfully', 'success');
      } else {
        await axiosInstance.post('/topics', { ...updatedTopic, agent_id: agentId });
        showSnackbar('Topic added successfully', 'success');
      }
      fetchTopics();
      setOpenAddEditTopicModal(false);
    } catch (error) {
      handleError(editMode ? 'Failed to update topic' : 'Failed to add topic', error);
    }
  };

  const confirmDelete = (actionId, topicId) => {
    setDeleteDialog({ open: true, actionId, topicId });
  };

  const handleDelete = async () => {
    try {
      if (deleteDialog.actionId) {
        await deleteAction(deleteDialog.actionId);
      }
      if (deleteDialog.topicId) {
        await deleteTopic(deleteDialog.topicId);
      }
      setDeleteDialog({ open: false, actionId: null, topicId: null });
    } catch (error) {
      handleError('Failed to delete action', error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog({ open: false, actionId: null, topicId: null });
  };

  const deleteAction = async (actionId) => {
    try {
      await axiosInstance.delete(`/actions/${actionId}`);
      fetchActions();
      showSnackbar('Action deleted successfully', 'success');
    } catch (error) {
      handleError('Failed to delete action', error);
    }
  };

  const deleteTopic = async (topicId) => {
    try {
      await axiosInstance.delete(`/topics/${topicId}`);
      await fetchTopics();
      showSnackbar('Topic deleted successfully', 'success');
    } catch (error) {
      handleError('Failed to delete action', error);
    }
  };

  const handleEditAction = (action) => {
    setNewAction({
      ...action,
      request_headers: action.request_headers,
      request_query_params: action.request_query_params,
      request_body: action.request_body,
      requires_confirmation: action.requires_confirmation,
      topic_id: action.topic_id,
    });
    setAvailableActionNames(PROVIDER_ACTIONS[action.provider]);
    setEditMode(true);
    setOpenAddEditActionModal(true);
  };

  const handleEditTopic = (topic) => {
    setNewTopic({
      ...topic,
      agent_id: topic.agent_id,
    });
    setEditMode(true);
    setOpenAddEditTopicModal(true);
  };

  const handleProviderChange = (provider) => {
    setNewAction({ ...newAction, provider: provider, action_name: '', action_type: '', request_method: '', request_url: '', request_headers: '{}', request_query_params: '{}', request_body: '{}', connection_string: '', query_text: '' });
    setAvailableActionNames(PROVIDER_ACTIONS[provider] || []);
  };

  const handleActionNameChange = (actionName) => {
    const actionType = ACTION_TYPE_MAP[actionName];
    setNewAction({
      ...newAction,
      action_name: actionName,
      action_type: actionType,
      request_method: actionType === 'API Call' ? 'GET' : '',
      request_url: actionType === 'API Call' ? '' : '',
      request_headers: actionType === 'API Call' ? '{}' : '',
      request_query_params: actionType === 'API Call' ? '{}' : '',
      request_body: actionType === 'API Call' ? '{}' : '',
      connection_string: actionType === 'SQL Query' ? '' : '',
      query_text: actionType === 'SQL Query' ? '' : '',
    });
  };

  const handleOpenAddActionModal = () => {
    setNewAction({ action_name: '', action_description: '', action_type: '', provider: '', request_method: '', request_url: '', request_headers: '{}', request_query_params: '{}', request_body: '{}', connection_string: '', query_text: '', topic_id: '', requires_confirmation: false });
    setAvailableActionNames([]);
    setEditMode(false);
    setOpenAddEditActionModal(true);
  };

  const handleCloseAddEditActionModal = () => {
    setOpenAddEditActionModal(false);
  };

  const handleOpenAddTopicModal = () => {
    setNewTopic({ topic_name: '', custom_prompt: '', input_arguments: '{}' });
    setOpenAddEditTopicModal(true);
  };

  const handleCloseAddTopicModal = () => {
    setOpenAddEditTopicModal(false);
  };

  const handleJSONBlur = (field, type) => {
    try {
      if (type === 'topic') {
        setNewTopic({ ...newTopic, [field]: JSON.stringify(JSON.parse(newTopic[field])) });
      } else {
        setNewAction({ ...newAction, [field]: JSON.stringify(JSON.parse(newAction[field])) });
      }
    } catch (error) {
      showSnackbar(`Invalid JSON format in ${field.replace(/_/g, ' ')}`, 'error');
    }
  };

  useEffect(() => {
    fetchActions();
    fetchTopics();
  }, [fetchActions, fetchTopics]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      minHeight="100vh"
      sx={{
        backgroundColor: '#f0f0f0',
        marginTop: 0,
        marginBottom: 0,
        paddingTop: '64px',
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          maxWidth: '100vh',
          backgroundColor: 'rgb(246, 246, 246)',
          borderRadius: '15px',
          padding: '20px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column'
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px">
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
            Actions for: {agentInfo.agent_name}
          </Typography>
          <Box>
            <Button
              variant="outlined"
              sx={{
                marginRight: '10px',
                backgroundColor: '#efefef',
                color: '#333333',
                borderColor: '#999999',
                '&:hover': {
                  borderColor: '#333333',
                  backgroundColor: '#ebebeb',
                },
              }}
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate('/agents')}
            >
              Back to Agents
            </Button>
            <Button
              variant="outlined"
              sx={{ marginRight: '10px', backgroundColor: '#e3f2fd' }}
              startIcon={<AddCircle />}
              onClick={handleOpenAddTopicModal}
            >
              Add Topic
            </Button>
            <Button
              variant="outlined"
              sx={{ backgroundColor: '#e3f2fd' }}
              startIcon={<AddCircle />}
              onClick={handleOpenAddActionModal}
            >
              Add Action
            </Button>
          </Box>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          topics.map((topic) => (
            <Accordion
              key={topic.topic_id}
              disableGutters
              expanded={!collapsedAccordions[topic.topic_id]}
              onChange={() => handleAccordionToggle(topic.topic_id)}
              sx={{
                marginBottom: '1rem',
                padding: '10px',
                backgroundColor: '#eeeeee',
                boxShadow: 'none',
                borderRadius: '15px!important',
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '15px',
                  backgroundColor: '#eeeeee',
                  '& .MuiAccordionSummary-content': {
                    pointerEvents: 'auto', // Allow pointer events on child elements
                  },
                }}
                onClick={(event) => {
                  if (event.target.closest('.icon-button')) {
                    event.stopPropagation();
                  }
                }}
              >
                <Box display="flex" alignItems="center" flexGrow={1} sx={{ pointerEvents: 'auto' }}>
                  <Typography sx={{ flexGrow: 1, fontWeight: 'bold' }}> {/* Topic name bold */}
                    {topic.topic_name}
                  </Typography>
                  {!topic.is_primary && (
                    <>
                      <IconButton
                        className="icon-button" // Add a specific class for targeting
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditTopic(topic);
                        }}
                        sx={{
                          marginRight: 1
                        }}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                      <IconButton
                        className="icon-button"
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          confirmDelete(null, topic.topic_id);
                        }}
                        sx={{
                          marginRight: 1
                        }}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
              {!loading && actions.find((action) => action.topic_id === topic.topic_id) === undefined && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  height="100%"
                >
                  <HourglassEmpty fontSize="large" color="disabled" sx={{ fontSize: 40 }} />
                  <Typography variant="body1" color="textSecondary" mt={2}>
                    You do not have any actions in this topic yet.
                  </Typography>
                </Box>
              )}
                <List>
                  {actions
                    .filter((action) => action.topic_id === topic.topic_id)
                    .map((action) => (
                      <ListItem
                        key={action.action_id}
                        sx={{
                          backgroundColor: '#fff',
                          borderRadius: '10px',
                          my: 1,
                          p: 2,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <ListItemText
                            primary={action.action_name}
                            secondary={action.action_description}
                            primaryTypographyProps={{ component: 'span' }}
                            secondaryTypographyProps={{ component: 'span' }}
                          />
                          <Box sx={{ display: 'flex', gap: 1, marginTop: 1 }}>
                            <Chip label={action.provider} sx={{ backgroundColor: '#f1f8e9', color: '#33691e' }} size="small" />
                            <Chip label={action.action_type} sx={{ backgroundColor: '#e0f7fa', color: '#006064' }} size="small" />
                          </Box>
                        </Box>
                        {action.action_type === 'Knowledgebase Query' && (
                          <Button
                            variant="outlined"
                            sx={{ marginRight: 2 }}
                            onClick={() => navigate(`/agents/${agentId}/actions/${action.action_id}/knowledgebase`)}
                          >
                            Manage Knowledgebase
                          </Button>
                        )}
                        <IconButton edge="end" sx={{ marginRight: 2 }} onClick={() => handleEditAction(action)}>
                          <EditIcon color="primary" />
                        </IconButton>
                        <IconButton edge="end" sx={{ marginRight: 2 }} onClick={() => confirmDelete(action.action_id, null)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </ListItem>
                    ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))
        )}

        <Dialog
          open={openAddEditActionModal}
          onClose={handleCloseAddEditActionModal}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>{editMode ? 'Edit Action' : 'Add Action'}</DialogTitle>
          <DialogContent>
          <TextField
              select
              fullWidth
              label="Topic"
              value={newAction.topic_id}
              onChange={(e) => setNewAction({ ...newAction, topic_id: e.target.value })}
              margin="normal"
              sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
              required
            >
              {topics.map((topic) => (
                <MenuItem key={topic.topic_id} value={topic.topic_id}>
                  {topic.topic_name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              fullWidth
              label="Provider Name"
              value={newAction.provider}
              onChange={(e) => handleProviderChange(e.target.value)}
              margin="normal"
              sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
              required
            >
              {Object.keys(PROVIDER_ACTIONS).map((provider) => (
                <MenuItem key={provider} value={provider}>
                  {provider}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              fullWidth
              label="Action Name"
              value={newAction.action_name}
              onChange={(e) => handleActionNameChange(e.target.value)}
              margin="normal"
              sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
              disabled={!newAction.provider}
              required
            >
              {availableActionNames.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              label="Action Type"
              value={newAction.action_type}
              margin="normal"
              sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
              InputProps={{ readOnly: true }}
              required
            />
            <TextField
                fullWidth
                label="Action Description"
                inputRef={descriptionRef}
                value={newAction.action_description}
                onChange={(e) => setNewAction({ ...newAction, action_description: e.target.value })}
                margin="normal"
                sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
                required
              />
            {newAction.action_type === 'API Call' && (
              <>
                <TextField
                  select
                  fullWidth
                  label="Request Method"
                  value={newAction.request_method}
                  onChange={(e) => setNewAction({ ...newAction, request_method: e.target.value })}
                  margin="normal"
                  sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
                  required
                >
                  {HTTP_METHODS.map((method) => (
                    <MenuItem key={method} value={method}>
                      {method}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  label="Request URL"
                  value={newAction.request_url}
                  onChange={(e) => setNewAction({ ...newAction, request_url: e.target.value })}
                  onBlur={() => !newAction.request_url && showSnackbar('Request URL is required', 'error')}
                  margin="normal"
                  sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
                  required
                />
                <TextField
                  fullWidth
                  label="Request Headers (JSON)"
                  value={newAction.request_headers}
                  onChange={(e) => setNewAction({ ...newAction, request_headers: e.target.value })}
                  onBlur={() => handleJSONBlur('request_headers')}
                  margin="normal"
                  multiline
                  InputProps={{ style: { fontFamily: 'Courier New, monospace' } }}
                  sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
                />
                <TextField
                  fullWidth
                  label="Request Query Params (JSON)"
                  value={newAction.request_query_params}
                  onChange={(e) => setNewAction({ ...newAction, request_query_params: e.target.value })}
                  onBlur={() => handleJSONBlur('request_query_params')}
                  margin="normal"
                  multiline
                  InputProps={{ style: { fontFamily: 'Courier New, monospace' } }}
                  sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
                />
                <TextField
                  fullWidth
                  label="Request Body (JSON)"
                  value={newAction.request_body}
                  onChange={(e) => setNewAction({ ...newAction, request_body: e.target.value })}
                  onBlur={() => handleJSONBlur('request_body')}
                  margin="normal"
                  multiline
                  InputProps={{ style: { fontFamily: 'Courier New, monospace' } }}
                  sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
                />
              </>
            )}

            {newAction.action_type === 'SQL Query' && (
              <>
                <TextField
                  fullWidth
                  label="Connection String"
                  value={newAction.connection_string}
                  onChange={(e) => setNewAction({ ...newAction, connection_string: e.target.value })}
                  margin="normal"
                  sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
                  required
                />
                <TextField
                  fullWidth
                  label="Query Text"
                  value={newAction.query_text}
                  onChange={(e) => setNewAction({ ...newAction, query_text: e.target.value })}
                  margin="normal"
                  sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
                  required
                />
              </>
            )}
            <Box display="flex" alignItems="center" my={2}>
              <Typography>Requires confirmation from the user?</Typography>
              <Switch
                checked={newAction.requires_confirmation}
                onChange={(e) => setNewAction({ ...newAction, requires_confirmation: e.target.checked })}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddEditActionModal} variant="outlined" color="error">
              Cancel
            </Button>
            <Button
              onClick={handleAddEditAction}
              variant="outlined"
              sx={{ backgroundColor: '#e3f2fd' }}
              disabled={!newAction.provider || !newAction.action_name || !newAction.action_description || (newAction.action_type === 'API Call' && (!newAction.request_method || !newAction.request_url)) || (newAction.action_type === 'SQL Query' && (!newAction.connection_string || !newAction.query_text))}
            >
              {editMode ? 'Update Action' : 'Add Action'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openAddEditTopicModal} onClose={handleCloseAddTopicModal} maxWidth="sm" fullWidth>
          <DialogTitle>Add Topic</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Topic Name"
              value={newTopic.topic_name}
              onChange={(e) => setNewTopic({ ...newTopic, topic_name: e.target.value })}
              margin="normal"
              sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
              required
            />
            <TextField
              fullWidth
              multiline
              label="Custom Prompt"
              value={newTopic.custom_prompt}
              onChange={(e) => setNewTopic({ ...newTopic, custom_prompt: e.target.value })}
              margin="normal"
              sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
            />
            <TextField
              fullWidth
              label="Input Arguments (JSON)"
              value={newTopic.input_arguments}
              onChange={(e) => setNewTopic({ ...newTopic, input_arguments: e.target.value })}
              onBlur={() => handleJSONBlur('input_arguments', 'topic')}
              margin="normal"
              multiline
              InputProps={{ style: { fontFamily: 'Courier New, monospace' } }}
              sx={{ borderRadius: '10px', backgroundColor: '#f0f0f0' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddTopicModal} variant="outlined" color="error">
              Cancel
            </Button>
            <Button
              onClick={handleAddEditTopic}
              variant="outlined"
              sx={{ backgroundColor: '#e3f2fd' }}
              disabled={!newTopic.topic_name}
            >
              {editMode ? 'Update Topic' : 'Add Topic'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={errorModal.open} onClose={() => setErrorModal({ open: false, title: '', description: '' })}>
          <DialogTitle>{errorModal.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{errorModal.description}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorModal({ open: false, title: '', description: '' })} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteDialog.open} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this {deleteDialog.topicId ? "topic" : "action"}? This cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default ActionsApp;
