import {
  Container,
  Box,
  Typography,
} from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const InsightsApp = () => {

  return (
    <Box display="flex" flexDirection="column" textAlign="center" justifyContent="center" alignItems="center" height="100vh" sx={{ backgroundColor: '#f0f0f0' }}>
      <Container
        maxWidth="md"
        sx={{ backgroundColor: 'rgb(246, 246, 246)', borderRadius: '15px', padding: '100px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', height: '90vh' }}
      >
        <ConstructionIcon sx={{ fontSize: 100, color: 'gray', mb: 3 }} />
        <Typography variant="h3" component="h1" gutterBottom sx={{ color: 'gray'}}>
          Coming Soon
        </Typography>
        <Typography variant="body1" color="textSecondary">
          We are working hard to bring you something amazing. Stay tuned!
        </Typography>
      </Container>
    </Box>
  );
};

export default InsightsApp;
