import {
    Box,
    Button,
    AppBar,
    Toolbar,
  } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from './Auth';
import { ReactComponent as Logo } from './assets/logo.svg';


function Navigation() {
    const { userId, secretToken } = useAuth();
    const buttonStyles = {
      textTransform: "none",
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 600,
      fontSize: "0.95rem",
      padding: '8px 16px',
      color: "#555",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "rgba(200, 240, 245, 0.3)",
      },
      marginRight: "20px",
    };
  
    return (userId && secretToken) ? (
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "rgb(245, 250, 251)",
          color: "#333",
          boxShadow: "none",
          borderBottom: "1px solid #e0e0e0",
          display: 'flex',
          padding: '0px 100px'
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", padding: '20px' }}>
            <Logo style={{ width: '120px', height: 'auto', fill: '#008ec2' }} />
          </Box>
          <Box>
            <Button
              component={Link}
              to="/agents"
              color="inherit"
              sx={buttonStyles}
            >
              Agents
            </Button>
            <Button
              component={Link}
              to="/insights"
              color="inherit"
              sx={buttonStyles}
            >
              Insights
            </Button>
            <Button
              component={Link}
              to="/signout"
              color="inherit"
              sx={buttonStyles}
            >
              Sign Out
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    ) : null;
  }

export default Navigation;
