import { createContext, useState, useContext, useCallback } from 'react';
import Config from './Config';

// Create AuthContext
const AuthContext = createContext();
const LOCAL_STORAGE_PREFIX = Config.LOCAL_STORAGE_PREFIX;
// Utility hook to manage state with localStorage
const useLocalStorageState = (key, initialValue = null) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_PREFIX + key);
    return storedValue !== null ? storedValue : initialValue;
  });

  const setStoredValue = useCallback((newValue) => {
    setValue(newValue);
    if (newValue !== null) {
      localStorage.setItem(LOCAL_STORAGE_PREFIX + key, newValue);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_PREFIX + key);
    }
  }, [key]);

  return [value, setStoredValue];
};

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [secretToken, setSecretToken] = useLocalStorageState('secretToken');
  const [userId, setUserId] = useLocalStorageState('userId');
  const [threadId, setThreadId] = useLocalStorageState('threadId');
  const [endUserId] = useState(Config.DEFAULT_END_USER_ID);
  const [apiBaseUrl] = useState(Config.API_BASE_URL);
  const [queryBaseUrl] = useState(Config.QUERY_BASE_URL);
  const [cdnBaseUrl] = useState(Config.CDN_BASE_URL)

  return (
    <AuthContext.Provider value={{ apiBaseUrl, queryBaseUrl, cdnBaseUrl, threadId, setThreadId, secretToken, setSecretToken, userId, setUserId, endUserId }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
