import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Button,
  Container,
  Typography,
  Box,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from './Auth';

function ChatbotApp() {
    const [agentInfo, setAgentInfo] = useState([]);
    const { agentId } = useParams();
    const navigate = useNavigate();
    const isScriptLoaded = useRef(false);

    const { secretToken, apiBaseUrl, cdnBaseUrl, endUserId } = useAuth();

    const axiosInstance = useMemo(() => {
        return axios.create({
          baseURL: apiBaseUrl,
          headers: {
            Authorization: `Bearer ${secretToken}`
          },
        });
      }, [apiBaseUrl, secretToken]);
      
    const backToAgents = () => {
      const embedChat = document.querySelector('#embed-chatbot-container');
      if (embedChat) {
        embedChat.remove();
      }
      navigate("/agents");
    }
    
    useEffect(() => {
      const loadAgent = async () => {
        try {
          const response = await axiosInstance.get(`/agents/${agentId}`);
          setAgentInfo(response.data);
        } catch (err) {
          console.error("Loading agent failed", err);
        }
      };
      loadAgent();
    }, [axiosInstance, agentId, setAgentInfo]);


    useEffect(() => {
      const manipulateChatbot = (chatbot) => {
        const container = document.querySelector('#embed-chatbot-container');
        if (container) {
          container.style.left = '50%';
          container.style.transform = 'translateX(-50%)';
          container.style.width = '80%';
          container.style.height = '80%';
          const header = document.querySelector('#embed-chatbot-header');
          /* This removes all event listeners */
          const outerHTML = header.outerHTML;
          header.outerHTML = outerHTML;
          /* Don't allow adding minimized class */
          const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
              if (mutation.type === "attributes" && mutation.attributeName === "class"
                && container.classList.contains('minimized')) {
                container.classList.remove('minimized');
              }
            });
          });
          observer.observe(container, {
            attributes: true,
            attributeFilter: ["class"],
          });
        }
        chatbot.toggleChatbot();
      }  
      const loadScript = () => {
        if (!isScriptLoaded.current) {
          const script = document.createElement('script');
          script.src = `${cdnBaseUrl}/chat.js?v=${new Date().getTime()}`;
          script.onload = () => {
            if (window.EmbedChatbot) {
              localStorage.setItem('QUljaGF0NjA0MzM_userId', endUserId);
              let chatbot = new window.EmbedChatbot(btoa(apiBaseUrl), btoa(cdnBaseUrl), btoa(secretToken), btoa(agentId));
              manipulateChatbot(chatbot);
            }
          };
          document.body.appendChild(script);
          isScriptLoaded.current = true;
          return () => {
            document.body.removeChild(script);
            isScriptLoaded.current = false;
          };
        }
      };
      loadScript();
    }, [endUserId, apiBaseUrl, cdnBaseUrl, secretToken, agentId]);
    

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        minHeight="100vh"
        sx={{
          backgroundColor: "#f0f0f0",
          paddingTop: "20px",
          marginTop: "10px"
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            backgroundColor: "rgb(246, 246, 246)",
            borderRadius: "15px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            padding: "15px",
            margin: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "bold",
              color: "#333",
            }}
          >
            Testing: {agentInfo.agent_name}
          </Typography>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#efefef",
              color: "#333333",
              borderColor: "#999999",
              "&:hover": {
                borderColor: "#333333",
                backgroundColor: "#ebebeb",
              },
            }}
            startIcon={<ArrowBackIcon />}
            onClick={backToAgents}
          >
            Back to Agents
          </Button>
        </Container>
      </Box>
    );
  }

export default ChatbotApp;
