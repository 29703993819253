import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Snackbar, 
  Alert
} from '@mui/material';
import { useAuth } from './Auth';

const SignOutApp = () => {
    const navigate = useNavigate();
    const { userId, secretToken, setSecretToken, setUserId, setThreadId } = useAuth();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    useEffect(() => {
      if (userId && secretToken) {
        setUserId(null);
        setSecretToken(null);
        setThreadId(null);
        setSnackbar({ open: true, message: 'Logging out...', severity: 'success' });
        setTimeout(() => {
          navigate('/');
        }, 500);  
      }
    }, [navigate, userId, secretToken, setUserId, setSecretToken, setThreadId]);

    const handleCloseSnackbar = () => {
      setSnackbar({ open: false, message: '', severity: 'error' });
    };

    return (
      <Box>
        <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
    );
  };

export default SignOutApp;
