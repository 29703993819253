import React, { useEffect, useState, useMemo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography, List, ListItem, Paper, Box, Avatar, CircularProgress, Container, Chip, TextField, Autocomplete, Switch, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AssistantIcon from '@mui/icons-material/Assistant';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from './Auth';
import ArchieIcon from './assets/archie.png';
import UserIcon from './assets/user.png';
import ChatIcon from '@mui/icons-material/Chat';
import { formatDistanceToNow } from 'date-fns';

function ConversationsApp() {
  const { agentId } = useParams();
  const { secretToken, apiBaseUrl } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showInternalActions, setShowInternalActions] = useState(true);
  const [agentInfo, setAgentInfo] = useState({ agent_name: 'Agent' });
  const [filteredUserIds, setFilteredUserIds] = useState([]);
  const navigate = useNavigate();

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: apiBaseUrl,
      headers: {
        Authorization: `Bearer ${secretToken}`,
      },
    });
  }, [apiBaseUrl, secretToken]);

  useEffect(() => {
    const initAgent = async () => {
      try {
        const response = await axiosInstance.get(`/agents/${agentId}`);
        setAgentInfo(response.data);
      } catch (err) {
        console.error('Loading agent failed', err);
      }
    };
    initAgent();
  }, [axiosInstance, agentId]);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await axiosInstance.get(`/conversations/agents/${agentId}`);
        const sortedConversations = groupAndSortConversations(response.data);
        setConversations(sortedConversations);
        setLoading(false);
      } catch (err) {
        console.error('Failed to load conversations', err);
        setLoading(false);
      }
    };
    fetchConversations();
  }, [axiosInstance, agentId]);

  const groupAndSortConversations = (convos) => {
    const grouped = convos.reduce((threads, query) => {
      const { thread_id } = query;
      if (!threads[thread_id]) {
        threads[thread_id] = [];
      }
      threads[thread_id].push(query);
      return threads;
    }, {});

    return Object.entries(grouped)
      .sort(([, a], [, b]) => {
        const latestA = Math.max(...a.map((convo) => convo.created_at));
        const latestB = Math.max(...b.map((convo) => convo.created_at));
        return latestB - latestA;
      })
      .map(([threadId, queries]) => ({ threadId, queries }));
  };

  const formatRelativeTime = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp * 1000), { addSuffix: true });
  };

  const uniqueUserIds = [...new Set(conversations.flatMap((conv) => conv.queries.map((query) => query.user_id)))];

  const filteredConversations = conversations.filter(
    ({ queries }) => 
      filteredUserIds.length === 0 || filteredUserIds.includes(queries[0].user_id)
  );

  const handleShowInternalActionsChange = () => {
    setShowInternalActions(!showInternalActions);
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      minHeight="100vh"
      sx={{
        backgroundColor: '#f0f0f0',
        marginTop: 0,
        marginBottom: 0,
        paddingTop: '64px',
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          maxWidth: '100vh',
          backgroundColor: 'rgb(246, 246, 246)',
          borderRadius: '15px',
          padding: '20px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingBottom: '15px',
            backgroundColor: 'rgb(246, 246, 246)',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Conversations for {agentInfo.agent_name}
          </Typography>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: '#efefef',
              color: '#333333',
              borderColor: '#999999',
              '&:hover': {
                borderColor: '#333333',
                backgroundColor: '#ebebeb',
              },
            }}
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/agents')}
          >
            Back to Agents
          </Button>
        </Box>

        {/* Autocomplete Filter by User ID */}
        <Box display="flex" alignItems="center" sx={{ gap: 2, width: "100%" }}>
          <Autocomplete
            multiple
            id="user-id-filter"
            options={uniqueUserIds}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => setFilteredUserIds(newValue)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter by User ID"
                placeholder="Select User IDs"
              />
            )}
            sx={{ flexGrow: 1, marginBottom: 2 }}

          />
          <FormControlLabel
            control={<Switch
              checked={showInternalActions}
              onChange={handleShowInternalActionsChange}
              inputProps={{ 'aria-label': 'controlled' }}/>}
            label={showInternalActions ? "Showing AI thought process" : "Hiding AI thought process"}
            sx={{
              flexGrow: 0,
              display: "flex",
              alignItems: "center",
              marginBottom: 2,
            }}
          />
        </Box>

        {!loading && filteredConversations.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            height="100%"
            padding="50px"
          >
            <HourglassEmpty fontSize="large" color="disabled" sx={{ fontSize: 80 }} />
            <Typography variant="body1" color="textSecondary" mt={2}>
              You did not have any conversations with your agent yet, start one!
            </Typography>
            <Button variant="outlined" sx={{ marginTop: 4 }} startIcon={<ChatIcon />} onClick={() => navigate(`/agents/${agentId}/test`)}>
                Start a Conversation
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: 'rgb(246, 246, 246)',
            }}
          >
            {filteredConversations.map(({ threadId, queries }, index) => (
              <Accordion
                key={threadId}
                disableGutters
                sx={{
                  marginBottom: '1rem',
                  padding: '10px',
                  backgroundColor: '#eeeeee',
                  boxShadow: 'none',
                  borderRadius: '15px!important',
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '15px',
                    backgroundColor: '#eeeeee',
                  }}
                >
                  {/* User ID Chip */}
                  <Chip
                    icon={<PersonIcon />}
                    label={queries[0].user_id}
                    size="small"
                    sx={{
                      backgroundColor: '#f1f8e9',
                      color: '#33691e',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginRight: '10px',
                      padding: '20px 10px',
                    }}
                  />
                  {/* Thread ID Chip with Icon inside */}
                  <Chip
                    icon={<QuestionAnswerIcon />}
                    label={`Thread ID: ${threadId}`}
                    size="small"
                    sx={{
                      backgroundColor: '#e0f7fa',
                      color: '#006064',
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: '20px 10px',
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                  }}
                >
                  <List>
                    {queries[0].messages.map((message, index) => (
                      <React.Fragment key={index}>
                        {/* User's query (query_text) */}
                        {message.type === 'human_message' && (
                          <ListItem key={`${threadId}-${index}`} sx={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                            <Box display="flex" flexDirection="column" alignItems="center" marginRight="10px">
                              <Avatar src={UserIcon} alt="User" />
                            </Box>
                            <Paper
                              elevation={0}
                              sx={{
                                padding: '10px 15px',
                                backgroundColor: '#dceefc',
                                maxWidth: '60%',
                                textAlign: 'right',
                                borderRadius: '15px',
                                marginBottom: '10px',
                              }}>
                              <Typography variant="body1">
                                {message.content}
                              </Typography>
                              <Box display="flex" justifyContent="flex-end">
                                <Typography variant="caption" color="textSecondary">
                                  {formatRelativeTime(queries[0].created_at)}
                                </Typography>
                              </Box>
                            </Paper>
                          </ListItem>
                        )}

                        {/* User's query (query_text) */}
                        {showInternalActions && ['request_topic_routing', 'execute_topic_routing', 'request_complete_or_escalate', 'execute_complete_or_escalate', 'request_action', 'execute_action'].includes(message.type) && (
                          <ListItem key={`${threadId}-${index}`} sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Box display="flex" flexDirection="column" alignItems="center" marginRight="10px">
                              <Avatar sx={{ bgcolor: '#e0f7fa', color: '#006064' }} alt="AI Thought Process">
                                <AutoAwesomeIcon />
                              </Avatar>
                            </Box>
                            <Paper
                              elevation={0}
                              sx={{
                                padding: '10px 15px',
                                backgroundColor: '#e0ecec',
                                maxWidth: '60%',
                                textAlign: 'left',
                                borderRadius: '15px',
                                marginBottom: '10px',
                              }}
                            >
                            <Chip
                              icon={<AssistantIcon />}
                              label={{
                                "request_topic_routing": `Requesting routing to topic: ${message.actions && message.actions.name ? message.actions.name.replace("RouteToTopic_", "") : ""}`,
                                "execute_topic_routing": `Routed to new topic`,
                                "request_complete_or_escalate": "Requesting completion or escalation",
                                "execute_complete_or_escalate": "Executed completion or escalation",
                                "request_action": `Requesting action: ${message.actions && message.actions.name ? message.actions.name : ""}`,
                                "execute_action": `Executed action: ${message.action_name ? message.action_name : ""}`
                              }[message.type]}
                              size="small"
                              sx={{
                                backgroundColor: '#e8fcff',
                                color: '#006064',
                                fontSize: 16,
                                fontWeight: 'bold',
                                padding: '15px 10px',
                                marginBottom: 1,
                              }}
                            />
                            {message.content && message.content !== "" && (
                              <Typography>
                                <strong>Content:</strong> {message.content}
                              </Typography>
                            )}
                            {message.actions && message.actions.args && Object.keys(message.actions.args).length > 0 && (
                              <Box display="flex" flexDirection="column">
                                <Typography sx={{marginBottom: 1}}><strong>Arguments:</strong></Typography>
                                {Object.entries(message.actions.args).map(([arg_name, arg_value]) => (
                                  <Box display="inline-flex">
                                    <Chip
                                    label={arg_name}
                                    size="small"
                                    sx={{
                                      backgroundColor: '#f1f8e9',
                                      color: '#006064',
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      padding: '0',
                                      marginRight: 1,
                                      marginBottom: 1
                                    }}></Chip>
                                    <Typography>{arg_value.toString()}</Typography>
                                  </Box>
                                ))}
                              </Box>
                            )}
                            <Box display="flex" justifyContent="flex-end">
                              <Typography variant="caption" color="textSecondary" textAlign="right">
                                {formatRelativeTime(queries[0].created_at)}
                              </Typography>
                            </Box>
                            </Paper>
                          </ListItem>
                        )}

                        {/* AI's response (answer_text) */}
                        {message.type === 'ai_message' && (
                          <ListItem key={`${threadId}-${index}`} sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Box display="flex" flexDirection="column" alignItems="center" marginRight="10px">
                              <Avatar src={ArchieIcon} alt="Archie" />
                            </Box>
                            <Paper
                              elevation={0}
                              sx={{
                                padding: '10px 15px',
                                backgroundColor: '#d4f8e8',
                                maxWidth: '60%',
                                textAlign: 'left',
                                borderRadius: '15px',
                                marginBottom: '10px',
                              }}
                            >
                              <Typography variant="body1">
                                {message.content}
                              </Typography>
                              <Box display="flex" justifyContent="flex-end">
                                <Typography variant="caption" color="textSecondary">
                                  {formatRelativeTime(queries[0].created_at)}
                                </Typography>
                              </Box>
                            </Paper>
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default ConversationsApp;
