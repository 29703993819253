import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Button,
  Backdrop,
  CircularProgress,
  Chip
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CleaningServices from '@mui/icons-material/CleaningServices';
import BuildCircle from '@mui/icons-material/BuildCircle';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from './Auth';


const KnowledgebaseApp = () => {
  const [documents, setDocuments] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [recentlyUploadedDocs, setRecentlyUploadedDocs] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [errorModal, setErrorModal] = useState({ open: false, title: '', description: '' });
  const [isOperating, setIsOperating] = useState({ status: false, message: ''});
  const [loading, setLoading] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState({ open: false, documentId: null }); // New state for delete confirmation dialog
  const { agentId, actionId } = useParams();
  const [actionInfo, setActionInfo] = useState({action_name: 'Action'});
  const navigate = useNavigate();

  const { secretToken, apiBaseUrl } = useAuth();

  const axiosInstance = useMemo(() => {
      return axios.create({
        baseURL: apiBaseUrl,
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
      });
    }, [apiBaseUrl, secretToken]);    

  useEffect(() => {
    const initAction = async () => {
        try {
            const response = await axiosInstance.get(`/actions/${actionId}`);
            setActionInfo(response.data);
        } catch (err) {
            console.error("Loading agent failed", err);
        }
    }
    initAction();
  }, [axiosInstance, actionId, setActionInfo]);

  // Set allowed formats (initially PDF only)
  const allowedFormats = {
    'application/pdf': ['.pdf'],
  };

  // Function to load files from the server
  const fetchDocuments = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/documents', {
        params: {
          action_id: actionId
        }
      });
      const filteredDocuments = response.data.filter(
        (doc) => !recentlyUploadedDocs.some((recent) => recent.document_id === doc.document_id)
      );
      setDocuments(filteredDocuments);
    } catch (error) {
      handleError('Failed to fetch documents', error);
    }
  }, [axiosInstance, actionId, recentlyUploadedDocs]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchDocuments();
      } catch (error) {
        handleError('Failed to fetch documents', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchDocuments]);

  const showSnackbar = useCallback((message, severity) => {
    setSnackbar({ open: true, message, severity });
  }, [setSnackbar]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const handleError = (defaultMessage, error) => {
    const errorMessage = error.response?.data?.error_description || defaultMessage;
    const errorName = error.response?.data?.error_name || 'Error';
    setErrorModal({ open: true, title: errorName, description: errorMessage });
  };

  const handleCloseErrorModal = () => {
    setErrorModal({ open: false, title: '', description: '' });
  };

  const handleAddDocuments = useCallback(async (files) => {
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file', files[i]);
      try {
        const response = await axiosInstance.post('/documents', formData, {
          params: {
            action_id: actionId
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadingFiles((prev) =>
              prev.map((u) =>
                u.file === files[i]
                  ? { ...u, progress: uploadPercentage }
                  : u
              )
            );
          },
        });

        const uploadedDoc = { ...response.data };
        setUploadingFiles((prev) =>
          prev.map((u) =>
            u.file === files[i]
              ? { ...u, progress: 100, status: 'success', document_id: uploadedDoc.document_id }
              : u
          )
        );
        setRecentlyUploadedDocs((prev) => [...prev, uploadedDoc]);
        showSnackbar(`File ${files[i].name} uploaded successfully`, 'success');

        fetchDocuments();
      } catch (error) {
        setUploadingFiles((prev) =>
          prev.map((u) =>
            u.file === files[i]
              ? { ...u, status: 'error' }
              : u
          )
        );
        handleError('Failed to upload document', error);
      }
    }
  }, [axiosInstance, actionId, fetchDocuments, showSnackbar]);

  const onDrop = useCallback((acceptedFiles) => {
    const MAX_FILE_SIZE_MB = 10;
  
    const newUploads = acceptedFiles.filter((file) => file.size <= MAX_FILE_SIZE_MB * 1024 * 1024);
  
    if (newUploads.length === 0) {
      newUploads.forEach((file) => {
        showSnackbar(`File ${file.name} exceeds the 10 MB limit and was not uploaded.`, 'error');
      });
      return;
    }
  
    // Add new uploads to the state using functional update to ensure you are working with the latest state.
    setUploadingFiles((prev) => [
      ...prev,
      ...newUploads.map((file) => ({
        file,
        progress: 0,
        status: 'uploading',
      }))
    ]);
  
    // Call handleAddDocuments with the new files only after setting the state
    handleAddDocuments(newUploads);
  }, [handleAddDocuments, showSnackbar]);
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFormats,
  });

  const handleDeleteConfirmed = async () => {
    try {
      await handleDeleteDocument(deleteDialog.documentId);
      setDeleteDialog({ open: false, documentId: null });
      setUploadingFiles((prev) => prev.filter((u) => u.document_id !== deleteDialog.documentId));
      setRecentlyUploadedDocs((prev) => prev.filter((doc) => doc.document_id !== deleteDialog.documentId));  
    } catch (error) {
      handleError('Failed to delete document', error);
    }
  };

  const handleDeleteDocument = async (documentId) => {
    try {
      await axiosInstance.delete(`/documents/${documentId}`, {
        params: {
          action_id: actionId
        }
      });
      showSnackbar('File deleted successfully', 'success');
      fetchDocuments();
    } catch (error) {
      handleError('Failed to delete document', error);
    }
  };

  const pollOperationStatus = useCallback((success_message) => {
    const poll = async () => {
      try {
        await axiosInstance.options('/');
        setIsOperating(false);
        showSnackbar(success_message, 'success');
      } catch (error) {
        setTimeout(poll, 2000);
      }
    };
    poll();
  }, [axiosInstance, showSnackbar]);

  const handleDigestData = useCallback(async () => {
    setIsOperating({status: true, message: 'Please wait, digesting all data...'});
    try {
      await axiosInstance.post(`/knowledgebase/${actionId}/digest`);
      showSnackbar('Started digesting all data...', 'info');
      pollOperationStatus('Successfully digested all data!');
      setUploadingFiles([]);
      setRecentlyUploadedDocs([]);
      fetchDocuments();
    } catch (error) {
      handleError('Failed to digest all data!', error);
    }
  }, [axiosInstance, actionId, pollOperationStatus, fetchDocuments, showSnackbar]);

  const handleResetData = async () => {
    setIsOperating({status: true, message: 'Please wait, clearing all data...'});
    try {
      await axiosInstance.delete('/documents', {
        params: {
          action_id: actionId
        }
      });
      setUploadingFiles([]);
      setRecentlyUploadedDocs([]);
      await axiosInstance.post(`/knowledgebase/${actionId}/reset`);
      await fetchDocuments();
      showSnackbar('Started clearing all data...', 'info');
      pollOperationStatus('Successfully cleared all data!');
    } catch (error) {
      handleError('Failed to clear all data!', error);
    } finally {
      setIsOperating(false);
    }
  };

  if (loading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{ backgroundColor: "#f0f0f0" }}
    >
      <Container
        maxWidth="md"
        sx={{
          backgroundColor: "rgb(246, 246, 246)",
          borderRadius: "15px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          height: "90vh",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
        >
          <Typography
            variant="h5"
            align="center"
            sx={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 'bold',
              color: '#333',
            }}
          >
            Knowledgebase for: {actionInfo.action_name}
          </Typography>
          <Button
            variant="outlined"
            sx={{ backgroundColor: '#e3f2fd' }} // Same style as KnowledgebaseApp button
            startIcon={<ArrowBackIcon />}
            onClick={()=>navigate(`/agents/${agentId}/actions`)} // Open modal on click
          >
            Back to Actions
          </Button>
        </Box>
        <Box
          {...getRootProps()}
          p={4}
          border="2px dashed #1976d2"
          borderRadius="10px"
          textAlign="center"
          bgcolor={isDragActive ? "#e3f2fd" : "#f5f5f5"}
          marginBottom={3}
          sx={{
            cursor: "pointer",
            '&:hover': {
              backgroundColor: "#e3f2fd",
            },
          }}
        >
          <input {...getInputProps()} />
          <CloudUploadIcon fontSize="large" color="primary" />
          <Typography variant="body1" color="primary" mt={2}>
            Click here to upload your file or drag and drop.
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Supported Formats: PDF only - more to come (10MB each)
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom marginBottom={2}>
          Your Documents
        </Typography>
        <Box sx={{ overflowY: "auto", flexGrow: 1, marginBottom: "20px" }}>
          {uploadingFiles.length === 0 && documents.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              height="100%"
            >
              <HourglassEmpty fontSize="large" color="disabled" />
              <Typography variant="body1" color="textSecondary" mt={2}>
                You don't have any documents, get started by uploading above!
              </Typography>
            </Box>
          ) : (
            <List>
              {uploadingFiles.map((upload) => (
                upload.status === 'success' || upload.status === 'uploading' ? (
                  <ListItem key={upload.file.name} sx={{ bgcolor: '#fff', borderRadius: '10px', my: 1, p: 2 }}>
                    <DescriptionIcon color="primary" sx={{ padding: '24px' }} />
                    <ListItemText
                      primary={upload.file.name}
                      secondary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {`Document ID: ${upload.document_id || 'N/A'}`}
                          <Chip
                            label={upload.is_processed ? "Digested" : "Not Digested"}
                            sx={{
                              backgroundColor: upload.is_processed ? '#e0f7fa' : '#ffebee',
                              color: upload.is_processed ? '#006064' : '#b71c1c',
                            }}
                            size="small"
                          />
                        </Box>
                      }
                      secondaryTypographyProps={{ component: 'span' }}
                      sx={{ ml: 2, marginLeft: 0 }}
                    />

                    <Box sx={{ flexGrow: 1, mx: 2, marginRight: '32px' }}>
                      <LinearProgress variant="determinate" value={upload.progress} color={upload.status === 'error' ? 'error' : 'primary'} />
                    </Box>
                    {upload.status === 'success' && (
                      <>
                        <CheckCircleIcon color="success" sx={{ marginRight: '64px' }} />
                        <IconButton edge="end" aria-label="delete" onClick={() => setDeleteDialog({ open: true, documentId: upload.document_id })} sx={{ right: '32px' }}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </>
                    )}
                    {upload.status === 'error' && (
                      <Box display="flex" alignItems="center" sx={{ marginRight: '64px' }}>
                        <ErrorIcon color="error" />
                        <IconButton onClick={() => handleAddDocuments([upload.file])}>
                          <ReplayIcon color="primary" />
                        </IconButton>
                      </Box>
                    )}
                  </ListItem>
                ) : null
              ))}
              {documents.map((doc) => (
                <ListItem key={doc.document_id} sx={{ bgcolor: '#fff', borderRadius: '10px', my: 1, p: 2 }}>
                  <DescriptionIcon color="primary" sx={{ padding: '24px' }} />
                  <ListItemText
                    primary={`File: ${doc.file_name}`}
                    secondary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {`Document ID: ${doc.document_id}`}
                        <Chip
                          label={doc.is_processed ? "Digested" : "Not Digested"}
                          sx={{
                            backgroundColor: doc.is_processed ? '#e0f7fa' : '#ffebee',
                            color: doc.is_processed ? '#006064' : '#b71c1c',
                          }}
                          size="small"
                        />
                      </Box>
                    }
                    secondaryTypographyProps={{ component: 'span' }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => setDeleteDialog({ open: true, documentId: doc.document_id })} sx={{ right: '32px' }}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Button variant="outlined" color="error" startIcon={<CleaningServices />} onClick={handleResetData} disabled={isOperating.status}>
            Reset All Data
          </Button>
          <Button variant="outlined" sx={{backgroundColor: '#e3f2fd'}} startIcon={<BuildCircle />} onClick={handleDigestData} disabled={isOperating.status}>
            Digest All Data
          </Button>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Dialog
          open={errorModal.open}
          onClose={handleCloseErrorModal}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
        >
          <DialogTitle id="error-dialog-title">{errorModal.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="error-dialog-description">
              {errorModal.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErrorModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteDialog.open} onClose={() => setDeleteDialog({ open: false, documentId: null })}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this document? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog({ open: false, documentId: null })} color="primary">Cancel</Button>
            <Button onClick={handleDeleteConfirmed} color="error">Delete</Button>
          </DialogActions>
        </Dialog>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isOperating && isOperating.status}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ ml: 2 }}>
            {isOperating.message}
          </Typography>
        </Backdrop>
      </Container>
    </Box>
  );
};

export default KnowledgebaseApp;
