import ChatbotApp from './Chatbot';
import KnowledgebaseApp from './Knowledgebase';
import ActionsApp from './Actions';
import AgentsApp from './Agents';
import ConversationsApp from './Conversations';
import InsightsApp from './Insights';
import SignInApp from './SignIn';
import SignOutApp from './SignOut';
import Navigation from './Navigation';
import { useNavigate, useLocation, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from 'react';
import { AuthProvider, useAuth } from './Auth';
import { Box } from "@mui/material";
import './App.css';


function AppContent() {
  const { userId, secretToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/' && userId && secretToken) {
      navigate('/agents');
    }
  }, [navigate, location, userId, secretToken]);

  return (
    <Box sx={{ paddingTop: (userId && secretToken) ? "32px" : "0" }}>
      <Routes>
        { !(userId && secretToken) ? (
          <Route path="*" element={<SignInApp />} />
        ) : (
          <>
            <Route path="/agents" element={<AgentsApp />} />
            <Route path="/agents/:agentId/actions" element={<ActionsApp />} />
            <Route path="/agents/:agentId/actions/:actionId/knowledgebase" element={<KnowledgebaseApp />} />
            <Route path="/agents/:agentId/test" element={<ChatbotApp />} />
            <Route path="/agents/:agentId/conversations" element={<ConversationsApp />} />
            <Route path="/insights" element={<InsightsApp />} />
            <Route path="/signout" element={<SignOutApp />} />
          </>
        )}
      </Routes>
    </Box>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navigation />
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
