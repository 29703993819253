const Config = {
    development: {
        API_BASE_URL: "http://0.0.0.0:8000",
        QUERY_BASE_URL: "http://0.0.0.0:8000",
        CDN_BASE_URL: "http://localhost:3001",
        DEFAULT_END_USER_ID: "1",
        LOCAL_STORAGE_PREFIX: "AIchat60433_",
    },
    production: {
        API_BASE_URL: "https://api.imarchie.com",
        QUERY_BASE_URL: "https://query.imarchie.com",
        CDN_BASE_URL: "https://embed.imarchie.com",
        DEFAULT_END_USER_ID: "ADMIN_TEST_USER",
        LOCAL_STORAGE_PREFIX: "AIchat60433_"
    }
};

export default Config[process.env.NODE_ENV];
